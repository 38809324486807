import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d246162 = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _0c0e1db4 = () => interopDefault(import('../pages/nominants/index.vue' /* webpackChunkName: "pages/nominants/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _29f8dfcd = () => interopDefault(import('../pages/user/index.ts' /* webpackChunkName: "pages/user/index" */))
const _3fdcfe1b = () => interopDefault(import('../pages/auth/change-password.vue' /* webpackChunkName: "pages/auth/change-password" */))
const _1c75ff9a = () => interopDefault(import('../pages/auth/impersonalize.vue' /* webpackChunkName: "pages/auth/impersonalize" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _5b89240d = () => interopDefault(import('../pages/test/nominants/index.vue' /* webpackChunkName: "pages/test/nominants/index" */))
const _1c821674 = () => interopDefault(import('../pages/user/nominants/index.vue' /* webpackChunkName: "pages/user/nominants/index" */))
const _965279dc = () => interopDefault(import('../pages/user/profile/index.vue' /* webpackChunkName: "pages/user/profile/index" */))
const _490b226a = () => interopDefault(import('../pages/user/team/index.vue' /* webpackChunkName: "pages/user/team/index" */))
const _7f095198 = () => interopDefault(import('../pages/user/vote.vue' /* webpackChunkName: "pages/user/vote" */))
const _000d7594 = () => interopDefault(import('../pages/user/team/create.vue' /* webpackChunkName: "pages/user/team/create" */))
const _2a6fc7db = () => interopDefault(import('../pages/auth/oauth/_provider.vue' /* webpackChunkName: "pages/auth/oauth/_provider" */))
const _0f3ac5b5 = () => interopDefault(import('../pages/test/nominants/_id.vue' /* webpackChunkName: "pages/test/nominants/_id" */))
const _00211ea4 = () => interopDefault(import('../pages/user/nominants/_id.vue' /* webpackChunkName: "pages/user/nominants/_id" */))
const _253d050e = () => interopDefault(import('../pages/nominants/_id.vue' /* webpackChunkName: "pages/nominants/_id" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/map",
    component: _0d246162,
    name: "map___ru___default"
  }, {
    path: "/nominants",
    component: _0c0e1db4,
    name: "nominants___ru___default"
  }, {
    path: "/ru",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/user",
    component: _29f8dfcd,
    name: "user___ru___default"
  }, {
    path: "/auth/change-password",
    component: _3fdcfe1b,
    name: "auth-change-password___ru___default"
  }, {
    path: "/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru___default"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login___ru___default"
  }, {
    path: "/ru/map",
    component: _0d246162,
    name: "map___ru"
  }, {
    path: "/ru/nominants",
    component: _0c0e1db4,
    name: "nominants___ru"
  }, {
    path: "/ru/user",
    component: _29f8dfcd,
    name: "user___ru"
  }, {
    path: "/test/nominants",
    component: _5b89240d,
    name: "test-nominants___ru___default"
  }, {
    path: "/user/nominants",
    component: _1c821674,
    name: "user-nominants___ru___default"
  }, {
    path: "/user/profile",
    component: _965279dc,
    name: "user-profile___ru___default"
  }, {
    path: "/user/team",
    component: _490b226a,
    name: "user-team___ru___default"
  }, {
    path: "/user/vote",
    component: _7f095198,
    name: "user-vote___ru___default"
  }, {
    path: "/ru/auth/change-password",
    component: _3fdcfe1b,
    name: "auth-change-password___ru"
  }, {
    path: "/ru/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru"
  }, {
    path: "/ru/auth/login",
    component: _8e1272a8,
    name: "auth-login___ru"
  }, {
    path: "/ru/test/nominants",
    component: _5b89240d,
    name: "test-nominants___ru"
  }, {
    path: "/ru/user/nominants",
    component: _1c821674,
    name: "user-nominants___ru"
  }, {
    path: "/ru/user/profile",
    component: _965279dc,
    name: "user-profile___ru"
  }, {
    path: "/ru/user/team",
    component: _490b226a,
    name: "user-team___ru"
  }, {
    path: "/ru/user/vote",
    component: _7f095198,
    name: "user-vote___ru"
  }, {
    path: "/user/team/create",
    component: _000d7594,
    name: "user-team-create___ru___default"
  }, {
    path: "/ru/user/team/create",
    component: _000d7594,
    name: "user-team-create___ru"
  }, {
    path: "/ru/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru"
  }, {
    path: "/ru/test/nominants/:id",
    component: _0f3ac5b5,
    name: "test-nominants-id___ru"
  }, {
    path: "/ru/user/nominants/:id",
    component: _00211ea4,
    name: "user-nominants-id___ru"
  }, {
    path: "/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru___default"
  }, {
    path: "/ru/nominants/:id",
    component: _253d050e,
    name: "nominants-id___ru"
  }, {
    path: "/test/nominants/:id",
    component: _0f3ac5b5,
    name: "test-nominants-id___ru___default"
  }, {
    path: "/user/nominants/:id",
    component: _00211ea4,
    name: "user-nominants-id___ru___default"
  }, {
    path: "/nominants/:id",
    component: _253d050e,
    name: "nominants-id___ru___default"
  }, {
    path: "/ru/*",
    component: _2efaf336,
    name: "all___ru"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___ru___default"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all___ru___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
