export const actions = {
  async nuxtServerInit ({ commit, dispatch }) {
    const votingUntil = Date.UTC(2023, 11, 31, 9) // 2023-12-31T12:00+03:00
    const votingDisabledViaRoute =
      this.app.router.currentRoute.fullPath === "/nominants?disabled=1"
    commit("app/SET_VOTING_CLOSED",
      Date.now() >= votingUntil || votingDisabledViaRoute)

    await Promise.all([
      dispatch("app/FETCH_EVENT"),
      // dispatch("app/FETCH_MENU"),
      // dispatch("live/FETCH_HALLS"),
    ])
  },
}
